import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"
import Text from "../components/Text"
import TheaterVideo from "../components/Theater/TheaterVideo"
import Image from "../components/Image"
import Seamless from "../components/SeamlessEmbed"

const FormLandingPage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const { heading, youtube, form, blurb } = data.allLandingFormsJson.nodes[0]
  return (
    <Layout className="form-landing-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
        videoIds={[youtube.code]}
      />

      <Section>
        <Container maxWidth={1100}>
          <Text as="h1" className="has-text-centered" text={heading} />
        </Container>
      </Section>

      <Section>
        <Container>
          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${youtube.code}`}
            controls
            playing
            overVideo
            iconOnly
            language={"en"}>
            <Image publicId={youtube.image} />
          </TheaterVideo>
        </Container>
      </Section>
      <Section>
        <Container>
          <Text text={blurb} />
        </Container>
      </Section>

      <Section colorBack blue>
        <Container maxWidth={1100}>
          <h4 style={{ textAlign: "center", color: "white" }}>
            Fill out the form below to get started
          </h4>
          <Seamless src={form} />
        </Container>
      </Section>
    </Layout>
  )
}

export const formLandingPageQuery = graphql`
  query formLandingQuery($title: String!) {
    allLandingFormsJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        youtube {
          code
          image
        }
        blurb
        form
      }
    }
  }
`

export default FormLandingPage
